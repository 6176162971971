import React from 'react';
import { graphql, Link } from 'gatsby';
import { RichText } from 'prismic-reactjs';

import { Layout, TitleAndMeta, Archive, NoteItem, Single } from '../components';

export default ({ data }) => {
  const page = data.prismic.allStatics.edges[0].node;
  return (
    <Layout>
      <TitleAndMeta title="Notes" />
      <Single>
        {RichText.render(page.title)}
        {RichText.render(page.content)}
      </Single>
    </Layout>
  );
};

export const query = graphql`
  query TermsAndConditionsQuery {
    prismic {
      allStatics(uid: "terms-and-conditions") {
        edges {
          cursor
          node {
            title
            content
            _meta {
              type
              uid
            }
          }
        }
      }
    }
  }
`;
